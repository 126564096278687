<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div style="float: right; font-size: 12px;">
          <table>
            <tr>
              <td>
                  <label style="margin-bottom: 6px;"> Fecha:</label>
                  <v-date-picker                    
                    v-model="range"    
                    class="date-picker"
                    locale="es-MX" size="sm"          
                    mode="range"
                    style="width: 210px; font-size: 12px !important;"
                  />  
              </td>
              <td>
                <label>Dirección: </label>
                <select v-model="direction_code" class="form-control" style="width: 210px; font-size: 12px; margin-top: -5px;" @change="changeFilters">
                  <option value="">Todas</option>
                  <option :value="direction.code" v-for="direction in directions">{{direction.name}}</option>
                </select>
              </td>
              <td>
                <label>Status: </label>
                <select v-model="stage_code" class="form-control" style="width: 210px; font-size: 12px; margin-top: -5px;" @change="changeFilters">
                <option value="">Todos</option>
                  <option :value="stage.code" v-for="stage in stages">{{stage.name}}</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <br /><br /><br />
        <div id="map" :style="'border: 1px solid; width: 100%; height: '+(height - 360)+'px;'"></div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import bot from '../../services/bot';
import store from '../../store'
import { Loader } from '@googlemaps/js-api-loader';
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

moment.tz.setDefault('America/Mexico_city');

export default {
  name: 'BotMap',
  components: { 'v-date-picker': DatePicker },
  data: function () {
		return {
            account: null,
            latitude: 0,
            longitude: 0,
            height: 1000,
            tickets: [],
            direction_code: '',
            directions: [],
            stage_code: '',
            stages: [],
            range: {
              start: new moment().startOf('month').toDate(),
              end: new moment().endOf("month").toDate(),
            }
        }
  },
  mounted: async function() {
    this.loading();

    this.latitude = localStorage.getItem('latitude')*1;
    this.longitude = localStorage.getItem('longitude')*1;

    this.account = localStorage.getItem('account');

    console.log(this.account);

    let response = await bot.getTickets();

    if(response.type == "success"){
      this.tickets = response.data;
    }

    let response2 = await bot.getDirections();

    if(response2.type == "success"){
      this.directions = response2.data;
    }

    let response3 = await bot.getStages();

    if(response3.type == "success"){
      this.stages = response3.data;
    }

    this.loadMap();
    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.users = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    loadMap(){
      const apiOptions = {
        apiKey: "AIzaSyBjRSxI62T4CTN4Jl_R-1PJoIl2cAo2pAA"
      }

      this.height = window.screen.height;

      const loader = new Loader(apiOptions);

      loader.load().then(() => {
        const map = this.displayMap();
      });
    },
    displayMap: async function() {
			const mapOptions = {
				center: { lat: this.latitude, lng: this.longitude },
				zoom: 14,
        mapTypeId: 'satellite',
        mapId: "434879d77db0d24b"
			};

			this.loading();

      const mapDiv = document.getElementById('map');
			const map = new google.maps.Map(mapDiv, mapOptions);

      this.tickets.forEach(async (ticket) => {
        if(ticket.geolocation){
          const position = ticket.geolocation.split(',');
          this.placeMarker(map, { lat: position[0]*1, lng: position[1]*1 }, ticket.type, ticket.status);
        }
        else{
          let geocoder = new google.maps.Geocoder();

          let _this = this;

          geocoder.geocode( { 'address': ticket.location+" Ameca Jalisco"}, function(results, status) {
            if (status == 'OK') {
              _this.placeMarker(map, results[0].geometry.location, ticket.type, ticket.status);
            } else {}
          });
        }
      });

      this.loaded();

			return map;
		},
    placeMarker: async function(map, location, type, status) {
      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

      const pinBackground = await this.$pin(type, status);

      const marker = new AdvancedMarkerElement({
        map,
        position: location,
        content: pinBackground.element,
      });
    },
    changeFilters: async function(){
      this.loading();
      let dates = [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")];

      let response = await bot.getFilteredTickets(dates, this.direction_code, this.stage_code);

      if(response.type == "success"){
        this.tickets = response.data;
        this.loadMap();
      }

      this.loaded();
    },
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  },
}
</script>
<style>
.date-picker input{
    font-size: 12px !important;
    margin-top: -3px;
    height: 32px;
}   
</style>